export default {
  BackBottom: {
    newMsgOne: '{n} nouveau message',
    newMsgOther: '{n} nouveau messages',
    bottom: 'Fond',
  },
  Time: {
    weekdays: 'Dimanche_Lundi_Mardi_Mercredi_Jeudi_Vendredi_Samedi'.split('_'),
    formats: {
      LT: 'HH:mm',
      lll: 'D/M/YYYY HH:mm',
      WT: 'dddd HH:mm',
      YT: 'Hier HH:mm',
    },
  },
  Composer: {
    send: 'Envoyer',
  },
  SendConfirm: {
    title: 'Envoyer une photo',
    send: 'Envoyer',
    cancel: 'Annuler',
  },
  RateActions: {
    up: 'Voter pour',
    down: 'Vote négatif',
  },
  Recorder: {
    hold2talk: 'Tenir pour parler',
    release2send: 'Libérer pour envoyer',
    releaseOrSwipe: 'Relâchez pour envoyer, balayez vers le haut pour annuler',
    release2cancel: 'Relâcher pour annuler',
  },
  Search: {
    search: 'Chercher',
  },
};
