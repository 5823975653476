import React, { useState, useEffect, useReducer } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { MsalProvider } from "@azure/msal-react";
import { Provider } from 'react-redux';
import store from 'contexts/store';
import Navbar from "./components/Navbar";
import Home from "./pages/Home/Home";
import Blog from "./pages/Blog/Blog";
import About from "./pages/About/About";
import Admin from "./pages/Admin/Admin";
import NoPage from "./pages/NoPage/NoPage";

const Pages = () => {

    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/index" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/about" element={<About />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="*" element={<NoPage />} />
        </Routes>
    )
}

const App = (props: any) => {
    const { pca } = props;
    return (
        <MsalProvider instance={pca}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                <Provider store={store}>
                        <Navbar />
                        <Pages />
                </Provider>
            </Box>
        </MsalProvider>
    );
};

export default App;