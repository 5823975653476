import React, { useState, useCallback, useRef, Fragment, useContext, useEffect, SyntheticEvent } from "react";
import { Dialog, DialogContent, DialogTitle, InputAdornment } from "@mui/material";
import { FormControlLabel, TextField, CircularProgress } from "@mui/material";
import { Checkbox, Button, Typography, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import validator from 'validator'
import { msalConfig, loginRequest } from "../authConfig";
import { useMsal } from "@azure/msal-react";



//show login/register tabs and closed icon in dialog title
const DialogTitleWithCloseIcon = (props: any) => {
    const {
        onClose,
        disabled,
        activeTab,
        setActiveTab
    } = props;

    return (
        <DialogTitle>
            <Box display="flex" justifyContent="space-between">
                <Typography variant="h6">Login</Typography>
                <IconButton
                    onClick={onClose}
                    disabled={disabled}
                    aria-label="Close"
                    size="medium">
                    <CloseIcon />
                </IconButton>
            </Box>
        </DialogTitle>
    );
}

//text field used to show user name in dialog
const UserNameTextField = (props: any) => {
    const { title, setUserName, setHasUserNameError } = props;
    const [status, setStatus] = useState<string | null>(null);
    return <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label={title}
        autoFocus
        autoComplete="off"
        type="text"
        onChange={(event: any) => {
            if (status != null) {
                setStatus(null);
            }
            let userId = event.target.value;    

            if (!(userId == null || userId === "")) {
                let isEmail = validator.isEmail(userId);
                if (!isEmail) {
                    setStatus("Invalid user name.")
                    setHasUserNameError(true);
                }
                else {
                    const emailDomain = userId.split('@')[1];
                    const tenantDomain = emailDomain?.toLowerCase();

                    if (msalConfig.allowedTenantDomains.includes(tenantDomain)) {
                        setUserName(userId)
                        setHasUserNameError(false);
                    }
                }
            }
            else
            {
                setHasUserNameError(true);
            }
        }}
        helperText={status}
        FormHelperTextProps={{ error: true }} />
}


//form for login the user.
const LoginForm = (props: any) => {
    const { loginType, setDialogOpen, status, setStatus, } = props;
    const [userName, setUserName] = useState<string>("");
    const { instance } = useMsal();
    const [hasUserNameError, setHasUserNameError] = useState<boolean>(true);
    const login = useCallback((event: any) => {
        event.preventDefault();
        setDialogOpen(false);
        const emailDomain = userName.split('@')[1];
        const tenantDomain = emailDomain?.toLowerCase();

        if (msalConfig.allowedTenantDomains.includes(tenantDomain)) {
            if (loginType === "popup") {
                instance.loginPopup({
                    scopes: loginRequest.scopes,
                    loginHint: userName ,
                }).catch(e => {
                    console.log(e);
                });
            }
            else if (loginType === "redirect") {
                instance.loginRedirect({
                    scopes: loginRequest.scopes,
                    loginHint: userName,
                }).catch(e => {
                    console.log(e);
                });
            }
        } else {
            alert('Login failed.');
        }

    }, [instance,userName,setDialogOpen,hasUserNameError]);


    return (
        <Box component="form" maxWidth="720px" onSubmit={login}>
            <Box sx={{ mb: 4 }}>
                <Typography variant="h5">
                    Please input your login account:
                </Typography>
                <Fragment>
                    <UserNameTextField title="Email Address (microsoft.com or demopro.net)"
                        setUserName={setUserName}
                        setHasUserNameError={setHasUserNameError}
                    />

                </Fragment>
                <Typography variant="body2" color="red">
                    {status}
                </Typography>
            </Box>
            <Box width="100%">
                <Fragment>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        size="large"
                        disabled={hasUserNameError}
                    >
                        Login
                    </Button>
                </Fragment>
            </Box>

        </Box>);
}

//login dialog 
const LoginDialog = (props: any) => {
    const {
        loginType,
        dialogOpen,
        setDialogOpen,
    } = props;

    const [loginStatus, setLoginStatus] = useState<string | null>(null);

    const onClose = useCallback(() => {
        setDialogOpen(false);
    }, [setDialogOpen]);

    return (
        <>
            <Dialog open={dialogOpen}
                onClose={onClose}
                maxWidth="lg" 
                hideBackdrop>
                <DialogTitleWithCloseIcon
                    onClose={onClose}
                />
                <DialogContent>
                    <LoginForm
                        loginType={loginType}
                        status={loginStatus}
                        setStatus={setLoginStatus}
                        setDialogOpen={setDialogOpen} />
                </DialogContent>
            </Dialog>
        </>
    );
}

export default LoginDialog;


