import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import React from 'react';
import FullReactWebChat, { createDirectLine, createDirectLineAppServiceExtension, createStore, createStyleSet } from 'botframework-webchat';
import { ConnectionStatus, DirectLine } from 'botframework-directlinejs';
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import AuthService from "services/auth.service"
import { loginRequest } from "authConfig";
import {
    InteractionRequiredAuthError,
    InteractionStatus,
} from "@azure/msal-browser";
import { appInsights } from 'common/appInsights';
import { SeverityLevel } from '@microsoft/applicationinsights-web';


function generateRandomUsername(): string {
    const alphabet = 'abcdefghijklmnopqrstuvwxyz';
    const upperCaseAlphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';

    let username = '';

    // First letter is uppercase
    username += upperCaseAlphabet.charAt(Math.floor(Math.random() * upperCaseAlphabet.length));

    // Middle four letters are lowercase
    for (let i = 0; i < 5; i++) {
        username += alphabet.charAt(Math.floor(Math.random() * alphabet.length));
    }

    // Last five letters are numbers
    for (let i = 0; i < 4; i++) {
        username += numbers.charAt(Math.floor(Math.random() * numbers.length));
    }

    return username;
}
const webChatStore = 
    createStore(
        {},
        ({ dispatch }:any) => (next:any) => (action:any) => {
            if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
                dispatch({
                    type: 'WEB_CHAT/SEND_EVENT',
                    payload: {
                        name: 'webchat/join',
                    },
                });
                console.log('Join');
            }

            if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
                //console.log('INCOMING ACTIVITY ', action.payload.activity);
            }

            // Add access token to outgoing messages
            if (action.type === 'DIRECT_LINE/POST_ACTIVITY') {
                action.payload.activity.channelData = {
                    accessToken: accessToken,
                };
                //console.log(`accessToken:${accessToken}`)
            }

            return next(action);
        }
    );

let accessToken:string|null = ""; //i don't know if there is any better method. but setState can't work to pass the parameter to createStore.
const MsBot = (props: any) => {
    const [loading, setLoading] = useState(true);
    const [userID, setUserID] = useState<string>("");
    const [directLine, setDirectLine] = useState<any>(null);
    const { instance, inProgress, accounts } = useMsal();
    const name = accounts[0] && accounts[0].username;

    const domain = process.env.REACT_APP_CUSTOM_DIRECTLINE_URL;


    const styleSet = createStyleSet({
        bubbleBackground: 'rgba(0, 0, 255, .1)',
        bubbleFromUserBackground: 'rgba(0, 255, 0, .1)',
        rootHeight: '100%',
        rootWidth: '100%',
        bubbleMaxWidth: 800,
        backgroundColor: 'paleturquoise'
    });

    const avatarOptions = {
        botAvatarInitials: 'GPT',
        userAvatarInitials: "ME"
    };

    const getDirectLine = useCallback(async () => {


        async function getDirectLineToken(accessToken: string): Promise<string | null> {
            let response: any = null;
            let conversationId = sessionStorage.getItem("conversationId");
            let directLineToken = sessionStorage.getItem("directLineToken");


            if (conversationId == null || directLineToken == null) {
                response = await AuthService.generateDirectlineToken(accessToken);
                if (response != null) {
                    sessionStorage.setItem("conversationId", response.conversationId);
                    sessionStorage.setItem("directLineToken", response.token);
                    directLineToken = response.token;
                }
            }
            else {
                try {
                    //get new token according to conversationId so that we can keep the chat history.
                    response = await axios.get(
                        !!domain ?
                            `${domain}/conversations/${conversationId}` :
                            `${process.env.REACT_APP_DIRECTLINE_URL}/conversations/${conversationId}`,
                        {
                            headers: { Authorization: 'Bearer ' + directLineToken }
                        });
                    if (response.status === 200) {
                        sessionStorage.setItem("directLineToken", response.data.token);
                        directLineToken = response.data.token;
                    }
                }
                catch (error) {
                    console.log(error);
                    appInsights.trackException({ error: (error as Error), severityLevel: SeverityLevel.Error });
                    directLineToken = null;
                }
            }
            return directLineToken;
        }

        async function createDirectLineObject(directLineToken: string): Promise<boolean> {
            try {
                let directLineObject = !!domain ?
                    await createDirectLineAppServiceExtension(
                        {
                            domain: domain,
                            token: directLineToken
                        })
                    : createDirectLine(
                        {
                            domain: process.env.REACT_APP_DIRECTLINE_URL,
                            token: directLineToken
                        })
                appInsights.trackTrace({
                    message: `Cereate directline successfully.token:${directLineToken}`
                });
                setDirectLine(directLineObject);
                setLoading(false);
                return true;
            }
            catch (error) {
                console.log(error);
                appInsights.trackException({ error: (error as Error), severityLevel: SeverityLevel.Error });
            }
            return false;
        }

        if (inProgress === InteractionStatus.None) {
            //get access token
            let token = await AuthService.getAccessToken(instance);
            
            accessToken = token;
            //get directline token
            if (token != null) {
                let directLineToken = await getDirectLineToken(token);

                //create directline object
                if (directLineToken != null) {
                    let result = await createDirectLineObject(directLineToken);
                    if (result) {
                        return;
                    }
                }
            }
            sessionStorage.removeItem("conversationId");
            sessionStorage.removeItem("directLineToken");
            //try again when encounter any failure.
            if (token != null) {
                let directLineToken = await getDirectLineToken(token);

                //create directline object
                if (directLineToken != null) {
                    await createDirectLineObject(directLineToken);
                }
            }
        }

    }, [instance, accounts, inProgress, setDirectLine]);


    useEffect(() => {

        if (loading === true) {
            let id = sessionStorage.getItem("userId");
            if (id == null) {
                id = name;
                sessionStorage.setItem("userId", id);
            }
            setUserID(id);
            getDirectLine();
        }
    }, [getDirectLine, setUserID, loading]);

    return loading === false && !!directLine  ? (
        <FullReactWebChat
            directLine={directLine}
            userID={userID}
            username={userID}
            store={webChatStore}
            styleSet={styleSet}
            styleOptions={avatarOptions}
        />
    ) : (
        <div>Loading...</div>
    );
};

export default MsBot;