import React, { useRef } from "react";
import { Stack, TextField, Button, styled } from "@mui/material";
import { Send } from "@mui/icons-material";

interface Props {
    value: string;
    onChange: (value: string) => void;
    onSend: (question: string) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    rows: number;
    onFocus?: () => void;
    onBlur?: () => void;
}

const StyledTextField = styled(TextField)(() => ({
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
    },
}));

export const MultilineInput = ({
    value,
    onChange,
    onSend,
    disabled,
    placeholder = "",
    clearOnSend = false,
    rows,
    onFocus,
    onBlur,
}: Props) => {
    const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
    const sendQuestion = () => {
        if (disabled || !value.trim()) {
            return;
        }

        onSend(value);

        if (clearOnSend) {
            onChange("");
        }

        // Make the input lose focus
        if (inputRef.current) {
            inputRef.current.blur();
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const newValue = event.target.value;
        if (!newValue) {
            onChange("");
        } else if (newValue.length < 32000) {
            onChange(newValue);
        }
    };

    const sendQuestionDisabled = disabled || !value.trim();

    return (
        <Stack
            direction="row"
            sx={{
                display: "flex",
                flexDirection: "row",
                border: "1px solid",
                borderColor: "divider",
                borderRadius: "4px",
            }}
        >
            <StyledTextField
                sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    borderColor: "transparent",
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                }}
                variant="outlined"
                multiline
                rows={rows}
                value={value}
                onChange={onQuestionChange}
                onKeyDown={onEnterPress}
                placeholder={placeholder}
                onFocus={onFocus}
                onBlur={onBlur}
                inputRef={inputRef}
            />
            <Button
                onClick={sendQuestion}
                onMouseDown={(e) => e.preventDefault()}
                disabled={sendQuestionDisabled}
            >
                <Send />
            </Button>
        </Stack>
    );
};