import React from 'react';
import { Button, Box, Typography, IconButton, CircularProgress, Stack, Switch, Select, MenuItem, FormControl, InputLabel, Grid } from '@mui/material';
import { Keyboard, RecordVoiceOver, RestartAlt } from "@mui/icons-material";


interface ChatHeaderProps {
    speechMode: string;
    queryMode: string;
    changeQueryMode: (event: any) => void;
    changeSpeechMode: (event: any) => void;
    handleClearClick: (event: any) => void;
}

const ChatHeader = ({ speechMode, changeSpeechMode, handleClearClick,queryMode,changeQueryMode }: ChatHeaderProps) => {
    return (

        <Box sx={{
            display: "flex",
            flexDirection: "row",
        }}>


            <Grid container alignItems="center" justifyContent="center" mb={1} mt={1} ml={2} mr={2} >
                <Grid >
                    <FormControl>
                        <InputLabel id="queryModeLabel">Query mode</InputLabel>
                        <Select labelId="queryModeLabel"
                            value={queryMode} defaultValue={queryMode} onChange={changeQueryMode} label="Query mode" size="small">
                            <MenuItem value="search">
                                <Stack direction="row">
                                    <Typography variant="body1" sx={{ ml: 1 }}>Cognitive Search</Typography>
                                </Stack>
                            </MenuItem>
                            <MenuItem value="embedding">
                                <Stack direction="row">
                                    <Typography variant="body1" sx={{ ml: 1 }}>Embedding</Typography>
                                </Stack>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs>
                    <Typography variant="h6" align="center">ChatGPT Voice Assistant</Typography>
                </Grid>
                
                <Grid>
                    <FormControl >
                        <InputLabel id="speechModeLabel">Speech input mode</InputLabel>
                        <Select labelId="speechModeLabel"
                            value={speechMode} defaultValue={speechMode} onChange={changeSpeechMode} label="Speech input mode" size="small">
                            <MenuItem value="interactive">
                                <Stack direction="row">
                                    <Keyboard />
                                    <Typography variant="body1" sx={{ ml: 1 }}>Interactive Mode</Typography>
                                </Stack>
                            </MenuItem>
                            <MenuItem value="direct">
                                <Stack direction="row">
                                    <RecordVoiceOver />
                                    <Typography variant="body1" sx={{ ml: 1 }}>Direct Mode</Typography>
                                </Stack>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid>
                    <IconButton onClick={handleClearClick} title="Clear Chat" size="medium">
                        <RestartAlt />
                    </IconButton>
                </Grid>
            </Grid>
            {/*<Stack direction="row" spacing={1} alignItems="center">*/}
            {/*    <Typography variant='body2'>直接模式</Typography>*/}
            {/*    <Switch checked={speechMode == "interactive"} onChange={changeSpeechMode} />*/}
            {/*    <Typography variant='body2'>交互模式</Typography>*/}
            {/*</Stack>*/}

        </Box>
    );
};

export default ChatHeader;