// rootReducer.ts
import { combineReducers } from 'redux';
import webChatReducer ,{ WebChatState } from './webChatReducer';
import qnaReducer, {QnaState } from './qnaReducer';
import settingsReducer, {SettingsState } from './settingsReducer';

const rootReducer = combineReducers({
    WebChat: webChatReducer,
    Qna: qnaReducer,
    Settings: settingsReducer,
});

export default rootReducer;

export interface RootState {
    WebChat: WebChatState;
    Qna: QnaState;
    Settings: SettingsState;
}