import axios from "axios";

const API_URL = process.env.REACT_APP_API_PATH;

const createAvatar = async (token:string, createModel: any): Promise<any> => {
    try {
        const response = await axios.post(
            API_URL + "/api/avatar/create",
            createModel,
            {
                headers: { Authorization: 'Bearer ' + token }
            }
        );
        if (response.status === 202) {
            return response.data;
        }
    } catch (error) {
        console.log(error);
    }
    return null;
};

const getJobStatus = async (token:string,jobId: string): Promise<any> => {
    try {
        const response = await axios.get(
            API_URL + `/api/avatar/status/${jobId}`,
            {
                headers: { Authorization: 'Bearer ' + token }
            }
        );
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.log(error);
    }
    return null;
};

const getDownloadUri = async (token: string, name: string): Promise<any> => {
    try {
        let response = await axios.get(
            API_URL + `/api/avatar/getdownloaduri?filepath=${encodeURIComponent(name)}`,
            {
                headers: { Authorization: 'Bearer ' + token }
            }
        )
        if (response.status === 200) {
            if (response.data.isSuccess) {
                return response.data.content.uri;
            }
        }

    } catch (error) {
        console.log(error);
    }

    return false;
};



const deleteAvatar = async (token: string, name: string): Promise<boolean> => {
    try {

        let response = await axios.post(
            API_URL + "/api/avatar/delete",
            {
                name
            },
            {
                headers: { Authorization: 'Bearer ' + token }
            }
        )
        if (response.status === 200) {
            if (response.data.isSuccess) {
                return true;
            }
        }
    }
    catch (error) {
        console.log(error);
    }

    return false;
};

const listAvatar = async (token: string): Promise<any | null> => {
    try {

        let response = await axios.post(
            API_URL + "/api/avatar/list",
            {
            },
            {
                headers: { Authorization: 'Bearer ' + token }
            }

        )
        if (response.status === 200) {
            if (response.data.isSuccess) {
                return response.data.content;
            }
        }
    }
    catch (error) {
        console.log(error);
    }

    return null;
};

const AvatarService = {
    createAvatar,
    getJobStatus,
    listAvatar,
    getDownloadUri,
    deleteAvatar
};

export default AvatarService;