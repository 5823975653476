import axios from "axios";
import authHeader from './auth-header'
import { decodeToken, isTokenExpired } from "../services/decode-jwt"
import {
    InteractionRequiredAuthError,
    InteractionStatus,
} from "@azure/msal-browser";
import { appInsights } from 'common/appInsights';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { loginRequest } from "authConfig";

import { removeTrailingSlash } from "common/utility"


const API_URL = removeTrailingSlash(process.env.REACT_APP_API_PATH);

const getBlobUploadUri = async (token: string, name: string): Promise<string | null> => {
    try {

        let response = await axios.get(
            API_URL + `/api/file/getuploaduri?filepath=${name}`,
            {
                headers: { Authorization: 'Bearer ' + token }
            }
        )
        if (response.status === 200) {
            if (response.data.isSuccess) {
                return response.data.content.uri;
            }
        }
    }
    catch (error) {
        console.log(error);
    }

    return null;
};

const getBlobDownloadUri = async (token: string, name: string): Promise<string | null> => {
    try {

        let response = await axios.get(
            API_URL + `/api/file/getdownloaduri?filepath=${encodeURIComponent(name)}`,
            {
                headers: { Authorization: 'Bearer ' + token }
            }
        )
        if (response.status === 200) {
            if (response.data.isSuccess) {
                return response.data.content.uri;
            }
        }
    }
    catch (error) {
        console.log(error);
    }

    return null;
};



const listBlob = async (token: string, prefix: string): Promise<any | null> => {
    try {

        let response = await axios.post(
            API_URL + `/api/file/list`,
            {
                prefix
            },
            {
                headers: { Authorization: 'Bearer ' + token }
            }
        )
        if (response.status === 200) {
            if (response.data.isSuccess) {
                return response.data.content;
            }
        }
    }
    catch (error) {
        console.log(error);
    }

    return null;
};


const deleteBlob = async (token: string, name: string): Promise<boolean> => {
    try {

        let response = await axios.post(
            API_URL + `/api/file/delete`,
            {
                name
            },
            {
                headers: { Authorization: 'Bearer ' + token }
            }
        )
        if (response.status === 200) {
            if (response.data.isSuccess) {
                return true;
            }
        }
    }
    catch (error) {
        console.log(error);
    }

    return false;
};

const StorageService = {
    getBlobUploadUri,
    getBlobDownloadUri,
    listBlob,
    deleteBlob
}
export default StorageService;