import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SettingsState {
    modelType: string | "";
    openAIEndpoint: string | "";
    openAIKey: string | "";
    gpt3_5DeploymentName: string | "";
    gpt4DeploymentName: string | "";
    gpt4_32kDeploymentName: string | "";
    textDavinci003: string | "";
    blobConnectionString: string | "";
    textEmbeddingAda002: string | "";
    azureSearchEndpoint: string | "";
    azureSearchKey: string | "";
    vectorDBEndpoint: string | "";
    speechKey: string | "";
    speechRegion: string | "";
    avatarKey: string | "";
    avatarRegion: string | "";
    trialNumber: string | "";
}

export const initialState: SettingsState = {
    modelType: "GPT3.5",
    openAIEndpoint: "",
    openAIKey: "",
    gpt3_5DeploymentName: "",
    gpt4DeploymentName: "",
    gpt4_32kDeploymentName: "",
    textDavinci003: "",
    blobConnectionString: "",
    textEmbeddingAda002: "",
    azureSearchEndpoint: "",
    azureSearchKey: "",
    vectorDBEndpoint: "",
    speechKey: "",
    speechRegion: "",
    avatarKey: "",
    avatarRegion: "",
    trialNumber: "",
};

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setSettings: (state, action: PayloadAction<SettingsState>) => {
            return action.payload;
        },
    },
});

export const { setSettings } = settingsSlice.actions;

export default settingsSlice.reducer;