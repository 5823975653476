import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MessageItem {
    user: string;
    assistant: string;
}

export interface QnaState {
    isLoading: boolean,
    answerStyle: string,
    currentPage: number,
    reserveContext: boolean,
    rows: number
    isPinned: boolean
    question: string,
    history: MessageItem[],
}

const initialState: QnaState = {
    isLoading: false,
    answerStyle: "Coder",
    currentPage: 1,
    reserveContext: true,
    rows: 4,
    isPinned: false,
    question: "",
    history: []
};

const qnaSlice = createSlice({
    name: 'qna',
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setAnswerStyle: (state, action: PayloadAction<string>) => {
            state.answerStyle = action.payload;
        },
        setCurrentPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload;
        },
        setReserveContext: (state, action: PayloadAction<boolean>) => {
            state.reserveContext = action.payload;
        },
        setRows: (state, action: PayloadAction<number>) => {
            state.rows = action.payload;
        },
        setIsPinned: (state, action: PayloadAction<boolean>) => {
            state.isPinned = action.payload;
        },
        setQuestion: (state, action: PayloadAction<string>) => {
            state.question = action.payload;
        },
        addMessage: (state, action: PayloadAction<MessageItem>) => {
            state.history.push(action.payload);
            state.currentPage = state.history.length;
        },
        deleteMessage: (state, action: PayloadAction<number>) => {
            state.history = state.history.filter((_, index) => index !== action.payload);
        },
        deleteMessages: (state, action: PayloadAction<{ startIndex: number; count: number }>) => {
            const { startIndex, count } = action.payload;
            state.history = [
                ...state.history.slice(0, startIndex),
                ...state.history.slice(startIndex + count),
            ];
        },
        updateMessage: (state, action: PayloadAction<MessageItem>) => {
            state.history[state.history.length - 1] = action.payload;
        },
        clearMessages: (state) => {
            state.history = [];
        },
    },
});

export const {
    setIsLoading,
    setAnswerStyle,
    setCurrentPage,
    setReserveContext,
    setRows,
    setIsPinned,
    setQuestion,
    addMessage,
    deleteMessage,
    deleteMessages,
    updateMessage,
    clearMessages,
} = qnaSlice.actions;

export default qnaSlice.reducer;