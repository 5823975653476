//webChatReducer.ts
import { createSlice,ThunkAction, Action } from '@reduxjs/toolkit';


export interface MessageItem {
    user: string;
    assistant: string;
}

export interface WebChatState {
    chatMsgs: MessageItem[];
    enableSpeech: boolean;
    speechMode: string;  //"direct", "interactive"
    queryMode: string;
}
const initialState: WebChatState = {
    chatMsgs: [],
    enableSpeech: false,
    speechMode: "direct",
    queryMode:"embedding"
};

const webChatSlice = createSlice({
    name: 'webChat',
    initialState,
    reducers: {
        setMessages: (state, action) => {
            state.chatMsgs = action.payload;
        },
        setSpeechMode: (state, action) => {
            state.speechMode = action.payload;
        },
        setEnableSpeech: (state, action) => {
            state.enableSpeech = action.payload;
        },
        setQueryMode: (state, action) => {
            state.queryMode = action.payload;
        }
    },
});

export const {
    setMessages,
    setSpeechMode,
    setEnableSpeech,
    setQueryMode,
} = webChatSlice.actions;

export default webChatSlice.reducer;
