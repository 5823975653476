export default {
  BackBottom: {
    newMsgOne: '{n} رسالة جديدة',
    newMsgOther: '{n} رسالة جديدة',
    bottom: 'الأسفل',
  },
  Time: {
    weekdays: 'الأحد_الإثنين_الثلاثاء_الأربعاء_الخميس_الجمعة_السبت'.split('_'),
    formats: {
      LT: 'HH:mm',
      lll: 'YYYY/M/D HH:mm',
      WT: 'HH:mm dddd',
      YT: 'HH:mm أمس',
    },
  },
  Composer: {
    send: 'إرسال',
  },
  SendConfirm: {
    title: 'إرسال صورة',
    send: 'أرسل',
    cancel: 'إلغاء',
  },
  RateActions: {
    up: 'التصويت',
    down: 'تصويت سلبي',
  },
  Recorder: {
    hold2talk: 'أستمر بالضغط لتتحدث',
    release2send: 'حرر للإرسال',
    releaseOrSwipe: 'حرر للإرسال ، اسحب لأعلى للإلغاء',
    release2cancel: 'حرر للإلغاء',
  },
  Search: {
    search: 'يبحث',
  },
};
