import { useState, useCallback, useEffect } from 'react';
import React from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Button, FormControl, FormHelperText, Typography, Box, List, ListItem, ListItemAvatar, Avatar, ListItemButton, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import ArticleIcon from '@mui/icons-material/Article';
import { useDropzone } from 'react-dropzone';
import FileListItem from './FileListItem';
import { Pagination } from '@mui/material';
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { AnonymousCredential, BlockBlobClient, newPipeline } from "@azure/storage-blob";
import AuthService from "services/auth.service";
import StorageService from "services/storage.service";


interface FileState {
    file: File;
    status: string;
}



const FileShare = () => {
    const [fileStates, setFileStates] = useState<FileState[]>([]);

    const [fileList, setFileList] = useState<any>(null);
    const { instance, inProgress, accounts } = useMsal();
    const pipeline = newPipeline(new AnonymousCredential());
    const account = instance.getActiveAccount();

    const [page, setPage] = useState(1);
    const itemsPerPage = 10;

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };
    const paginatedFileList = fileList?.slice((page - 1) * itemsPerPage, page * itemsPerPage);

    const onAccepted = useCallback((acceptedFiles: File[]) => {

        setFileStates(prevFileStates => {
            const newFileStates = [...prevFileStates];
            for (let file of acceptedFiles) {
                newFileStates.push({ file: file, status: "0" });
            }
            return newFileStates;
        });



    }, [setFileStates])

    const showFiles = useCallback(async () => {
        if (account != null) {
            let accessToken = await AuthService.getAccessToken(instance);
            let list: any;
            if (accessToken != null) {
                if (account.idTokenClaims && account.idTokenClaims.oid) {
                    list = await StorageService.listBlob(accessToken,`${account.idTokenClaims.oid}/`);
                }
                let newFileList = list.map((file: any, i: number) => {
                    file.name = file.name.split('/')[1];
                    return file;
                });
                setFileList(newFileList);
            }
        }
    }, [fileStates])

    const upload = useCallback(async (onSuccess: () => void) => {
        try {
            if (account != null) {
                if (inProgress === InteractionStatus.None) {

                    //get access token
                    let file = fileStates[0].file;
                    let accessToken = await AuthService.getAccessToken(instance);

                    if (accessToken != null) {
                        let fileName = "";
                        if (account.idTokenClaims && account.idTokenClaims.oid) {
                            fileName = `${account.idTokenClaims.oid}/${file.name}`;
                        }
                        else {
                            fileName = `${file.name}`;
                        }
                        let uri = await StorageService.getBlobUploadUri(accessToken, fileName);
                        if (uri != null) {
                            const blockBlobClient = new BlockBlobClient(
                                uri,
                                pipeline
                            );

                            await blockBlobClient.uploadData(file, {
                                blockSize: 8 * 1024 * 1024, // 4MB block size
                                concurrency: navigator.hardwareConcurrency, // 20 concurrency
                                onProgress: (ev: any) => console.log(ev),
                            });
                            console.log("Successfully uploaded file:", blockBlobClient.name);
                            removeFromList(0);
                            onSuccess();

                        }

                    }
                }
            }
        } catch (err: any) {
            console.log(err);
        }
    }, [fileStates])


    useEffect(() => {
        showFiles();
    }, []);

    useEffect(() => {

        if (fileStates.length > 0) {
            if (fileStates[0].status === "0") {
                setFileStates(prevFileStates => {
                    const newFileStates = prevFileStates;
                    newFileStates[0].status = "1";
                    return newFileStates;
                });
                upload(showFiles);
            }
        }

    }, [fileStates]);

    const deleteBlob = useCallback(async (name: string) => {
        if (account != null) {
            let accessToken = await AuthService.getAccessToken(instance);

            if (accessToken != null) {
                let fileName = "";

                if (account.idTokenClaims && account.idTokenClaims.oid) {
                    fileName = `${account.idTokenClaims.oid}/${name}`;
                }
                else {
                    fileName = `${name}`;
                }

                let result = await StorageService.deleteBlob(accessToken, fileName);

                if (result) {
                    showFiles();
                }
            }
        }

    }, []);

    const downloadBlob = useCallback(async (name: string) => {
        if (account != null) {
            let accessToken = await AuthService.getAccessToken(instance);

            if (accessToken != null) {
                let fileName = "";
                
                    if (account.idTokenClaims && account.idTokenClaims.oid) {
                        fileName = `${account.idTokenClaims.oid}/${name}`;
                    }
                    else {
                        fileName = `${name}`;
                    }
                

                let url = await StorageService.getBlobDownloadUri(accessToken, fileName);
                if (url) {
                    const link = document.createElement('a');
                    link.target = "_blank";
                    link.download = name;
                    link.href = url;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(url);
                }
            }
        }

    }, []);


    const removeFromList = useCallback((index: number) => {
        setFileStates(prevFileStates => {
            const newFileStates = [...prevFileStates];
            newFileStates.splice(index, 1);
            return newFileStates;
        });
    }, []);



    const { fileRejections, getRootProps, getInputProps, open } =
        useDropzone({
            onDropAccepted: onAccepted,
            noClick: true,
            noKeyboard: true
        });

    return (
        <>
            <Box
                {...getRootProps()}
                sx={{
                    border: 1,
                    borderRadius: 1,
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                    paddingY: 3,
                    paddingX: 1,
                    '&:hover': {
                        borderColor: 'text.primary',
                    },
                    '&:focus-within': {
                        borderColor: 'primary.main',
                        borderWidth: 2,
                    }
                }}>
                <FormControl
                    sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <input {...getInputProps()} />
                    <CloudUploadIcon sx={{ fontSize: 40 }} color={'primary'} />
                    <Typography variant="caption" textAlign="center" sx={{ paddingY: 1 }}>
                        Drag and drop files here, or click to select files
                    </Typography>
                    <Button variant="contained" onClick={open} sx={{ marginBottom: 1 }}>
                        Upload
                    </Button>
                    <FormHelperText> {fileRejections[0]?.errors[0]?.message} </FormHelperText>
                </FormControl>
                <Box
                    component="ul"
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        p: 0.5,
                        m: 0,
                    }}>
                    {fileStates?.map((fileState, i) =>
                        <FileListItem key={"fileListItem-" + fileState.file.name} name={fileState.file.name} disabled={fileState.status !== "0"} onDelete={() => removeFromList(i)} />
                    )}

                </Box>

            </Box>
            <Box>
                <List>
                    {paginatedFileList?.map((file: any, i: number) =>
                        <ListItem key={"listItem-" + file.name}

                            secondaryAction={
                                <>
                                    <IconButton edge="end" aria-label="download" onClick={() => downloadBlob(file.name)}>
                                        <DownloadIcon />
                                    </IconButton>
                                    <IconButton edge="end" aria-label="delete" onClick={() => deleteBlob(file.name)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </>}
                        >
                            <ListItemAvatar>
                                <Avatar>
                                    <ArticleIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemButton >
                                {file.name}
                            </ListItemButton>
                        </ListItem>
                    )}
                </List>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                    <Pagination
                        count={fileList ? Math.ceil(fileList.length / itemsPerPage) : 1}
                        page={page}
                        onChange={handleChangePage}
                        color="primary"
                    />
                </Box>

            </Box>
        </>

    );
};

export default FileShare;