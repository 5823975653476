import { useState, useCallback, useEffect } from 'react';
import React from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Button, FormControl, FormHelperText, Typography, Box, List, ListItem, ListItemAvatar, Avatar, ListItemButton, IconButton, Stack, Switch, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import ArticleIcon from '@mui/icons-material/Article';
import { useDropzone } from 'react-dropzone';
import FileListItem from './FileListItem';
import { Pagination } from '@mui/material';
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { AnonymousCredential, BlockBlobClient, newPipeline } from "@azure/storage-blob";
import AuthService from "services/auth.service";
import KBService from "services/kb.service";


interface FileState {
    file: File;
    status: string;
}


const FilesUploader = () => {
    
    const [fileStates, setFileStates] = useState<FileState[]>([]);

    const [fileList, setFileList] = useState<any>(null);
    const { instance, inProgress, accounts } = useMsal();
    const pipeline = newPipeline(new AnonymousCredential());
    const account = instance.getActiveAccount();

    const [page, setPage] = useState(1);
    const itemsPerPage = 10;

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };
    const paginatedFileList = fileList?.slice((page - 1) * itemsPerPage, page * itemsPerPage);

    const [fileMode, setFileMode] = useState(true);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    const handleInputModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFileMode(event.target.checked);
    };

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    };

    const handleContentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContent(event.target.value);
    };

    const saveTextFile = useCallback(async () => {
        if (account != null) {
            let accessToken = await AuthService.getAccessToken(instance);
            if (accessToken != null) {
                if (title && content) {
                    const file = new File([content], `${title}.txt`, { type: 'text/plain' });
                    const formData: FormData = new FormData();
                    formData.append('file', file);
                    formData.append('category', '')
                    await KBService.uploadKB(accessToken, formData);
                    showFiles();
                    setTitle('');
                    setContent('');
                }
                
            }
           
        }
    }, [title, content]);

    const onAccepted = useCallback((acceptedFiles: File[]) => {

        setFileStates(prevFileStates => {
            const newFileStates = [...prevFileStates];
            for (let file of acceptedFiles) {
                newFileStates.push({ file: file, status: "0" });
            }
            return newFileStates;
        });



    }, [setFileStates])

    const showFiles = useCallback(async () => {
        if (account != null) {
            let accessToken = await AuthService.getAccessToken(instance);
            let list: any;
            if (accessToken != null) {

                
                    list = await KBService.listKB(accessToken, "");
                    if (list != null) {
                        setFileList(list);
                    }
                

            }
        }
    }, [fileStates])

    

    const upload = useCallback(async (onSuccess: () => void) => {
        try {
            if (account != null) {
                if (inProgress === InteractionStatus.None) {

                    //get access token
                    let file = fileStates[0].file;
                    let accessToken = await AuthService.getAccessToken(instance);

                    if (accessToken != null) {

                        const formData: FormData = new FormData();
                        formData.append('file', file);
                        formData.append('category', '');
                        // Add check for fileName
                        //if (/^[a-zA-Z-_0-9. ]+$/.test(file.name)) {
                            await KBService.uploadKB(accessToken, formData);
                            removeFromList(0);
                            onSuccess();
                        //} else {
                        //    alert('The file name contains Chinese characters. Please rename it before uploading.')
                        //    removeFromList(0);
                        //}

                    }
                }
            }
        } catch (err: any) {
            console.log(err);
        }
    }, [fileStates])

    useEffect(() => {
        if (fileStates.length > 0) {
            if (fileStates[0].status === "0") {
                setFileStates(prevFileStates => {
                    const newFileStates = prevFileStates;
                    newFileStates[0].status = "1";
                    return newFileStates;
                });
                upload(showFiles);
            }
        }
        showFiles();
    }, [fileStates]);

    const deleteKB = useCallback(async (name: string) => {
        if (account != null) {
            let accessToken = await AuthService.getAccessToken(instance);

            if (accessToken != null) {
                let fileName = `${name}`;
                let result = await KBService.deleteKB(accessToken,fileName);

                if (result) {
                    showFiles();
                }
            }
        }

    }, []);

    const downloadKB = useCallback(async (name: string) => {
        if (account != null) {
            let accessToken = await AuthService.getAccessToken(instance);

            if (accessToken != null) {
                let response = await KBService.downloadKB(accessToken, name);
                if (!!response) {
                    const blob = new Blob([response.data], { type: response.headers['content-type'] });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.target = "_blank";
                    //link.download = name;
                    link.href = url;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(url);

                }
            }
        }
    }, []);

    const handleListItemClick = useCallback(async (name: string) => {
        if (!fileMode && name.endsWith('.txt')) {
            let accessToken = await AuthService.getAccessToken(instance);

            if (accessToken != null) {
                let fileName = `${name}`

                let response = await KBService.downloadKB(accessToken, fileName);
                if (response) {
                    //const blob = await response.blob();
                    const blob = new Blob([response.data], { type: response.headers['content-type'] });
                    const text = await blob.text();
                    setContent(text);
                    setTitle(name.slice(0, -4));
                }
            }
        }
    }, [fileMode]);

    const removeFromList = useCallback((index: number) => {
        setFileStates(prevFileStates => {
            const newFileStates = [...prevFileStates];
            newFileStates.splice(index, 1);
            return newFileStates;
        });
    }, []);


    const clearInputs = () => {
        setTitle('');
        setContent('');
    };

    const { fileRejections, getRootProps, getInputProps, open } =
        useDropzone({
            onDropAccepted: onAccepted,
            noClick: true,
            noKeyboard: true,
            accept: {
                'text/pdf': ['.pdf'],
                'text/txt': ['.txt'],
                'text/docx': ['.doc','.docx'],
            } 
        });

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant='body2'>Text mode</Typography>
                    <Switch checked={fileMode} onChange={handleInputModeChange} color="default" />
                    <Typography variant='body2'>File mode</Typography>
                </Stack>
            </Box>
            {!fileMode ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <TextField label="Title" value={title} onChange={handleTitleChange} size="small" />

                        <Stack direction="row" spacing={1}>
                            <Button variant="outlined" onClick={clearInputs} disabled={!title && !content}>
                                Clear
                            </Button>
                            <Button variant="contained" onClick={saveTextFile} disabled={!title || !content}>
                                Save
                            </Button>
                        </Stack>
                    </Box>
                    <TextField
                        label="Content"
                        multiline
                        rows={5}
                        value={content}
                        onChange={handleContentChange}
                        size="small"
                        sx={{ width: '100%', marginTop: 1 }}
                    />
                </Box>
            ) : (
                <Box
                    {...getRootProps()}
                    sx={{
                        border: 1,
                        borderRadius: 1,
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                        paddingY: 3,
                        paddingX: 1,
                        '&:hover': {
                            borderColor: 'text.primary',
                        },
                        '&:focus-within': {
                            borderColor: 'primary.main',
                            borderWidth: 2,
                        }
                    }}>
                    <FormControl
                        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <input {...getInputProps()} />
                        <CloudUploadIcon sx={{ fontSize: 40 }} color={'primary'} />
                        <Typography variant="caption" textAlign="center" sx={{ paddingY: 1 }}>
                            Drag and drop files here, or click to select files
                        </Typography>
                        <Button variant="contained" onClick={open} sx={{ marginBottom: 1 }}>
                            Upload
                        </Button>
                        <FormHelperText> {fileRejections[0]?.errors[0]?.message} </FormHelperText>
                    </FormControl>
                    <Box
                        component="ul"
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            listStyle: 'none',
                            p: 0.5,
                            m: 0,
                        }}>
                        {fileStates?.map((fileState, i) =>
                            <FileListItem key={"fileListItem-" + fileState.file.name}
                                name={fileState.file.name} disabled={fileState.status !== "0"}
                                onDelete={() => removeFromList(i)} />
                        )}

                    </Box>

                </Box>
            )}
            <Box>
                <List>
                    {paginatedFileList?.map((file: any, i: number) =>
                        <ListItem key={"listItem-" + file.name}

                            secondaryAction={
                                <>
                                    <IconButton edge="end" aria-label="download" onClick={() => downloadKB(file.name)}>
                                        <DownloadIcon />
                                    </IconButton>
                                    <IconButton edge="end" aria-label="delete" onClick={() => deleteKB(file.name)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </>}
                        >
                            <ListItemAvatar>
                                <Avatar>
                                    <ArticleIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemButton onClick={() => handleListItemClick(file.name)}>
                                {file.name}
                            </ListItemButton>
                        </ListItem>
                    )}
                </List>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                    <Pagination
                        count={fileList ? Math.ceil(fileList.length / itemsPerPage) : 1}
                        page={page}
                        onChange={handleChangePage}
                        color="primary"
                    />
                </Box>

            </Box>
        </>

    );
};

export default FilesUploader;