import React, { useState, useEffect, useCallback } from "react";
import { useMsal } from '@azure/msal-react';
import AvatarService from "../services/avatar.service";
import {
    Button,
    TextField,
    Box,
    Stack,
    List,
    ListItem,
    Typography,
    ListItemAvatar,
    Avatar,
    ListItemButton,
    IconButton,
    Switch,
    FormGroup,
    FormControlLabel,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import ArticleIcon from "@mui/icons-material/Article";
import { Pagination } from "@mui/material";
import AuthService from "../services/auth.service";

const AvatarCreator = () => {
    const { instance } = useMsal();
    const [name, setName] = useState("");
    const [content, setContent] = useState("");
    const [jobId, setJobId] = useState("");
    const [videoList, setVideoList] = useState<any>(null);
    const [page, setPage] = useState(1);
    const [isProcessing, setIsProcessing] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [videoSrc, setVideoSrc] = useState('');
    const itemsPerPage = 10;

    useEffect(() => {
        showAvatars();
    }, [instance]);

    const showAvatars = async () => {
        let accessToken = await AuthService.getAccessToken(instance);
        if (accessToken) {
            let list: any;
            if (accessToken != null) {
                list = await AvatarService.listAvatar(accessToken);
                if (list != null) {
                    setVideoList(list);
                }

            }
            

        }

    };

    const handleVideoToggle = (event: React.MouseEvent<HTMLVideoElement, MouseEvent>) => {
        const videoElement = event.currentTarget;
        if (videoElement.paused) {
            videoElement.play();
        } else {
            videoElement.pause();
        }
    };

    // 添加一个handleVideoClick函数来处理点击事件
    const handleVideoClick = async (name: string) => {
        let accessToken = await AuthService.getAccessToken(instance);
        if (accessToken) {
            let fileName = `${name}`
            let url = await AvatarService.getDownloadUri(accessToken, fileName);
            if (url) {
                setVideoSrc(url);
                setOpenDialog(true);
            }
        }
    };

    // 添加一个handleCloseDialog函数来关闭Dialog
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setVideoSrc('');
    };

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const paginatedVideoList = videoList?.slice((page - 1) * itemsPerPage, page * itemsPerPage);

    const handleCreateAvatar = async () => {
        let accessToken = await AuthService.getAccessToken(instance);
        if (accessToken) {

            const createModel = {
                Name: name,
                Content: content,
                Description: ""
            };
            setIsProcessing(true);
            const result = await AvatarService.createAvatar(accessToken,createModel);
            if (result) {
                setJobId(result.id);
                pollJobStatus(accessToken, result.id);
            }
            else {
                setErrorMessage("Failed to create avatar");
                setIsProcessing(false);
            }
        }
    };

    const pollJobStatus = async (token:string,jobId: string) => {
        while (true) {
            const result = await AvatarService.getJobStatus(token,jobId);
            if (result) {
                if (result.status === "Succeeded") {
                    setIsProcessing(false);
                    showAvatars()
                    break;
                }
            }
            else {
                setIsProcessing(false);
                break;
            }
            await new Promise((resolve) => setTimeout(resolve, 3000));
        }
    };

    const deleteAvatar = useCallback(async (name: string) => {

        let accessToken = await AuthService.getAccessToken(instance);
        if (accessToken) {

        if (accessToken != null) {
            let fileName = "";
            fileName = `${name}`;

            let result = await AvatarService.deleteAvatar(accessToken, fileName);
            if (result) {
                showAvatars()
            }
            }
        }

    }, []);

    const downloadAvatar = useCallback(async (name: string) => {
        let accessToken = await AuthService.getAccessToken(instance);
        if (accessToken) {
            let fileName = `${name}`
            let url = await AvatarService.getDownloadUri(accessToken, fileName);
            if (url) {
                const link = document.createElement('a');
                link.target = "_blank";
                link.download = name;
                link.href = url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            }
        }
    }, []);

    const clearInputs = () => {
        setName('');
        setContent('');
    };


    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <TextField label="Name" value={name} onChange={(e) => setName(e.target.value)} size="small" />

                    <Stack direction="row" spacing={1}>
                        <Button variant="outlined" onClick={clearInputs} disabled={!name && !content}>
                            Clear
                        </Button>
                        <Button variant="contained" onClick={handleCreateAvatar} disabled={!name || !content || isProcessing}>
                            Create
                        </Button>
                    </Stack>
                </Box>
                <TextField
                    label="内容"
                    multiline
                    rows={5}
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    size="small"
                    sx={{ width: '100%', marginTop: 1 }}
                />
            </Box>
            {errorMessage && (
                <Box px={2} py={1}>
                    <Typography color="error">{errorMessage}</Typography>
                </Box>
            )}
            <List>
                {paginatedVideoList?.map((video:any, index:number) => (
                    <ListItem key={index}>
                        <ListItemAvatar>
                            <Avatar>
                                <ArticleIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemButton onClick={() => handleVideoClick(video.name)}>
                            {video.name}
                        </ListItemButton>
                        <IconButton edge="end" aria-label="download" onClick={() => downloadAvatar(video.name)}>
                            <DownloadIcon />
                        </IconButton>
                        <IconButton edge="end" aria-label="delete" onClick={() => deleteAvatar(video.name)}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItem>
                ))}
            </List>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                maxWidth="md"
                fullWidth
            >
                <video onClick={handleVideoToggle}
                    src={videoSrc}
                    autoPlay
                    controls
                    playsInline
                    width="100%" />
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
                <Pagination
                    count={videoList ? Math.ceil(videoList.length / itemsPerPage) : 1}
                    page={page}
                    onChange={handleChangePage}
                    color="primary"
                />
            </Box>
        </>
    );
};

export default AvatarCreator;