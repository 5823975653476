import React, { useContext, useState, useEffect } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Box, Stack, Typography } from "@mui/material";
import { Chat, DriveFileMove, Assistant, Telegram } from "@mui/icons-material";
import MsBot from "components/MsBot";
import FileShare from "components/FileShare";
import Sidebar from "components/Sidebar";
import { useMsal } from "@azure/msal-react";
import Qna from "components/Qna";
import { WebChat } from "components/WebChat"

interface MainProps {
    currentItem: string;
}

// Main component to render different components based on the selected item
const Main = (props: MainProps) => {
    switch (props.currentItem) {
        case "QnA":
            return (
                < Qna />
            );
        case "Chat":
            return (
                <Box sx={{ flexGrow: 1, display: "block", flexDirection: "column" }} >
                    {<WebChat />}
                </Box >
            );
        case "Bot":
            return (
                <Box sx={{ flexGrow: 1, overflowY: "auto" }} >
                    {<MsBot />}
                </Box >
            );
        case "File":
            return (
                <Box sx={{ flexGrow: 1, ml: 2, mr: 2 }}  >
                    <Box display="flex" justifyContent="center">
                        <Typography variant="h6">File Share</Typography>
                    </Box>
                    <FileShare />
                </Box >
            );

        default:
            return null;
    }
};

// Home component to display the main layout with sidebar and main content
const Home = () => {
    const [currentItem, setCurrentItem] = useState<string>("QnA");

    // Load the current item from sessionStorage if available
    useEffect(() => {
        let item = sessionStorage.getItem("currentHomeItem");
        if (item != null) {
            setCurrentItem(item);
        }
    }, [setCurrentItem]);

    const items = [
        {
            id: "QnA",
            title: "Assistant",
            icon: <Assistant />,
        },
        {
            id: "Chat",
            title: "GPT Chat",
            icon: <Telegram />,
        },
        {
            id: "Bot",
            title: "GPT Bot",
            icon: <Chat />,
        },

        {
            id: "File",
            title: "File Share",
            icon: <DriveFileMove />,
        },
    ];

    // Handle item click and update the current item in state and sessionStorage
    const handleItemClick = (itemId: string) => {
        setCurrentItem(itemId);
        sessionStorage.setItem("currentHomeItem", itemId);
    };

    return (
        <Stack sx={{ flexGrow: 1, display: "flex" }} direction="row">
            <AuthenticatedTemplate>
                <Sidebar
                    items={items}
                    currentItem={currentItem}
                    handleItemClick={handleItemClick}
                />
                <Main currentItem={currentItem} />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Box>
                    <Typography>欢迎您访问Demopro.net! 我将为您演示Azure OpenAI的各种应用场景。</Typography>
                    <Typography>您可以使用microsoft.com账号或者demopro.net账号登录，如果您没有账号的话，请您联系我</Typography>
                    <Typography>您登录以后，请您尽快进入设置页面来配置您的Azure服务以便于长久使用。在配置之前，您将有10次的试用问答机会。</Typography>
                    <Typography>本网站不提供SLA，如果需要保证某段时间稳定运行，请提前跟我联系</Typography>
                    <Typography>欢迎您向我反馈您使用过程中遇到的任何问题，我的联系方式是:hanxia@microsoft.com</Typography>
                </Box>
            </UnauthenticatedTemplate>
        </Stack>
    );
};

export default Home;