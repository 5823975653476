import axios from "axios";
import authHeader from './auth-header'
import { decodeToken, isTokenExpired } from "./decode-jwt"
import {
    InteractionRequiredAuthError,
    InteractionStatus,
} from "@azure/msal-browser";
import { appInsights } from 'common/appInsights';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { loginRequest } from "authConfig";

import { removeTrailingSlash } from "common/utility"


const API_URL = removeTrailingSlash(process.env.REACT_APP_API_PATH);


const uploadKB = async (token: string, formData: FormData): Promise<boolean> => {

    let response = await axios.post(
        API_URL + "/api/kb/upload",
        formData,
        {
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'multipart/form-data'
            }
        }
    )
    if (response.status === 200) {
        return true;
    }
    return false
}


const downloadKB = async (token: string, name: string): Promise<any> => {
    try {
        let response = await axios.get(
            API_URL + `/api/kb/download?filePath=${encodeURIComponent(name)}`,
            {
                responseType: 'blob',
                headers: { Authorization: 'Bearer ' + token }
            }
        );
        
        if (response.status === 200) {
            return response;
        } else {
            console.error('Download failed:', response.statusText);
        }
    }
    catch (error) {
        console.log(error);
    }

    return null;
};

//const downloadKB = async (token: string, name: string): Promise<any> => {
//    try {

//        const response = await fetch(API_URL + `/api/kb/download?filePath=${encodeURIComponent(name)}`);

//        if (response.ok) {

//            return response;
//        }
//        else {
//            console.error('Download failed:', response.statusText);
//        }
//    }
//    catch (error) {
//        console.log(error);
//    }

//    return false;
//};


const listKB = async (token: string, prefix: string): Promise<any | null> => {
    try {

        let response = await axios.post(
            API_URL + `/api/kb/list`,
            {
                prefix
            },
            {
                headers: { Authorization: 'Bearer ' + token }
            }
        )
        if (response.status === 200) {
            if (response.data.isSuccess) {
                return response.data.content;
            }
        }
    }
    catch (error) {
        console.log(error);
    }

    return null;
};



const deleteKB = async (token: string, name: string): Promise<boolean> => {
    try {

        let response = await axios.post(
            API_URL + `/api/kb/delete`,
            {
                name
            },
            {
                headers: { Authorization: 'Bearer ' + token }
            }
        )
        if (response.status === 200) {
            if (response.data.isSuccess) {
                return true;
            }
        }
    }
    catch (error) {
        console.log(error);
    }

    return false;
};

const KBService = {
    downloadKB,
    listKB,
    deleteKB,
    uploadKB
}
export default KBService;