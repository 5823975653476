import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    Tooltip,
    Tab
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import SettingsIcon from '@mui/icons-material/Settings';
import { TabContext, TabList, TabPanel } from "@mui/lab";

import AuthService from "services/auth.service";
import SettingsService from "services/settings.service";
import { RootState } from 'contexts/rootReducer';
import { setSettings } from "../contexts/settingsReducer";


const AppSettings = () => {
    const { instance } = useMsal();
    const [open, setOpen] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [tabValue, setTabValue] = useState('1');
    const dispatch = useDispatch();
    const settings = useSelector((state: RootState) => state.Settings);


    useEffect(() => {
        loadSettings();

    }, [instance]);

    useEffect(() => {
        if (open) {
            loadSettings();
        }
    }, [open]);

    const loadSettings=()=> {
        const fetchSettings = async () => {
            let accessToken = await AuthService.getAccessToken(instance);
            if (accessToken) {
                let result = await SettingsService.getSettings(accessToken);
                if (result) {
                    const { trialNumber, ...rest } = result;
                    const trialNumberString = trialNumber === -1 ? "Unlimited" : trialNumber.toString();
                    dispatch(setSettings({ trialNumber: trialNumberString, ...rest }))
                    setIsLoaded(true);
                }
            }
        };
        fetchSettings();
        setErrorMessage("");
    }

    const showError = (message: string) => {
        setErrorMessage(message);
    };
    // Handle input changes
    const handleChange = (event: any) => {
        const { name, value } = event.target;
        dispatch(setSettings({ ...settings, [name]: value }));
        //setSettings(prevState => ({ ...prevState, [name]: value }));
    };

    // Handle tab change
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    // Open settings dialog
    const handleClickOpen = () => {
        setOpen(true);
    };

    // Close settings dialog
    const handleClose = () => {
        setOpen(false);
    };

    // Handle form submission
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        // Validation logic
        const openAIEndpointRegex = /^https:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?$/;
        const openAIKeyRegex = /^[a-zA-Z0-9]{32}$/;

        if (settings && settings.openAIEndpoint !== "") {
            if (!openAIEndpointRegex.test(settings.openAIEndpoint)) {
                showError('Valid OpenAI Endpoint is required');
                return;
            }
        }
        if (settings && settings.openAIKey !== "") {
            if (!openAIKeyRegex.test(settings.openAIKey)) {
                showError('Valid OpenAI Key is required');
                return;
            }
        }

        //if (!modelType) {
        //    showError('Valid Model Type is required.');
        //    return;
        //}

        //if (modelType === 'GPT3.5' && !gpt3_5DeploymentName) {
        //    showError('GPT3.5 Deployment Name is required.');
        //    return;
        //}

        //if (modelType === 'GPT4' && !gpt4DeploymentName) {
        //    showError('GPT4 Deployment Name is required.');
        //    return;
        //}

        //if (modelType === 'GPT4-32k' && !gpt4_32kDeploymentName) {
        //    showError('GPT4-32k Deployment Name is required.');
        //    return;
        //}


        //if (tabValue === '2') {
        //    if (!textDavinci003 || !blobConnectionString) {
        //        showError('Text Davinci003 and Blob Connection String are required.');
        //        return;
        //    }

        //    const azureSearchValid = azureSearchEndpoint && azureSearchKey;
        //    const vectorDBValid = vectorDBEndpoint && textEmbeddingAda002;

        //    if (!azureSearchValid && !vectorDBValid) {
        //        showError('Either both Azure Search Endpoint and Azure Search Key, or both Qdrant DB Endpoint and Text Embedding Ada002 Deployment Name are required.');
        //        return;
        //    }

        //    if (azureSearchValid && !openAIEndpointRegex.test(azureSearchEndpoint)) {
        //        showError('Invalid Azure Search Endpoint. It must be a valid HTTPS domain.');
        //        return;
        //    }

        //    if (vectorDBValid && !openAIEndpointRegex.test(vectorDBEndpoint)) {
        //        showError('Invalid Qdrant DB Endpoint. It must be a valid HTTPS domain.');
        //        return;
        //    }
        //}

        //if (tabValue === '3') {
        //    if (!speechKey || !speechRegion) {
        //        showError('Speech Key and Speech Region are required.');
        //        return;
        //    }
        //}

        //const formData = new FormData(event.currentTarget);
        //const data = Object.fromEntries(formData);

        let accessToken = await AuthService.getAccessToken(instance);
        if (accessToken) {
            if (settings) {
                const { trialNumber, ...rest } = settings;
                let result = SettingsService.saveSettings(accessToken, { ...rest });
                if (!result) {
                    showError('Unable to save settings.');
                }
            }
        }
        handleClose();
    };

    return (
        <>
            <Tooltip title="Settings">
                <IconButton color="inherit" sx={{ mr: 2 }} onClick={handleClickOpen} aria-label="configure your azure services">
                    <SettingsIcon />
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Service Settings</DialogTitle>

                <TabContext value={tabValue}>
                    <TabList onChange={handleTabChange}>
                        <Tab label="Chat GPT" value="1" />
                        <Tab label="Private Domain" value="2" />
                        <Tab label="Speech" value="3" />
                    </TabList>
                    <Box component="form" onSubmit={handleSubmit}>

                        <TabPanel value="1">
                            <Typography mb={2} variant="h6">Basic settings for enabling ChatGPT experience</Typography>
                            <TextField
                                autoFocus
                                margin="dense"
                                name="openAIEndpoint"
                                label="OpenAI Endpoint"
                                type="text"
                                fullWidth
                                value={settings.openAIEndpoint}
                                onChange={handleChange}
                            />

                            <TextField
                                margin="dense"
                                name="openAIKey"
                                label="OpenAI API Key"
                                type="text"
                                fullWidth
                                value={settings.openAIKey}
                                onChange={handleChange}
                            />
                            <FormControl fullWidth >
                                <InputLabel id="modelTypeLabel">Model Type</InputLabel>
                                <Select
                                    name="modelType"
                                    labelId="modelTypleLabel"
                                    value={settings.modelType}
                                    defaultValue={settings.modelType}
                                    onChange={handleChange}
                                    label="OpenAI Model Type"
                                >
                                    <MenuItem value="GPT3.5">GPT3.5</MenuItem>
                                    <MenuItem value="GPT4">GPT4</MenuItem>
                                    <MenuItem value="GPT4-32k">GPT4-32k</MenuItem>
                                </Select>
                            </FormControl>
                            {settings.modelType === 'GPT3.5' && (
                                <TextField
                                    margin="dense"
                                    name="gpt3_5DeploymentName"
                                    label="gpt-35-turbo deployment name"
                                    type="text"
                                    fullWidth
                                    value={settings.gpt3_5DeploymentName}
                                    onChange={handleChange}
                                />
                            )}
                            {settings.modelType === 'GPT4' && (
                                <TextField
                                    margin="dense"
                                    name="gpt4DeploymentName"
                                    label="gpt-4 deployment name"
                                    type="text"
                                    fullWidth
                                    value={settings.gpt4DeploymentName}
                                    onChange={handleChange}
                                />
                            )}
                            {settings.modelType === 'GPT4-32k' && (
                                <TextField
                                    margin="dense"
                                    name="gpt4_32kDeploymentName"
                                    label="gpt-4-32k deployment name"
                                    type="text"
                                    fullWidth
                                    value={settings.gpt4_32kDeploymentName}
                                    onChange={handleChange}
                                />
                            )}
                        </TabPanel>
                        <TabPanel value="2">
                            <Typography mb={2} variant="h6">Advanced setting to make GPT use custom data to reply users. There are two options: Azure search and Embedding. You can enable both or one of them.</Typography>
                            <TextField
                                autoFocus
                                margin="dense"
                                name="blobConnectionString"
                                label="Azure blob storage ConnectionString"
                                type="text"
                                fullWidth
                                value={settings.blobConnectionString}
                                onChange={handleChange}
                            />

                            <TextField
                                margin="dense"
                                name="textDavinci003"
                                label="text-davinci-003 deployment name"
                                type="text"
                                fullWidth
                                value={settings.textDavinci003}
                                onChange={handleChange}
                            />
                            <Typography mt={2} variant="subtitle1">Azure search option:</Typography>

                            <TextField
                                margin="dense"
                                name="azureSearchEndpoint"
                                label="Azure Search Service Endpoint"
                                type="text"
                                fullWidth
                                value={settings.azureSearchEndpoint}
                                onChange={handleChange}
                            />
                            <TextField
                                margin="dense"
                                name="azureSearchKey"
                                label="Azure Search Service Management Key"
                                type="text"
                                fullWidth
                                value={settings.azureSearchKey}
                                onChange={handleChange}
                            />
                            <Typography mt={2} variant="subtitle1">Embedding option:</Typography>

                            <TextField
                                margin="dense"
                                name="vectorDBEndpoint"
                                label="Qdrant DB Endpoint"
                                type="text"
                                fullWidth
                                value={settings.vectorDBEndpoint}
                                onChange={handleChange}
                            />
                            <TextField
                                margin="dense"
                                name="textEmbeddingAda002"
                                label="text-embedding-ada-002 Deployment Name"
                                type="text"
                                fullWidth
                                value={settings.textEmbeddingAda002}
                                onChange={handleChange}
                            />
                        </TabPanel>
                        <TabPanel value="3">
                            <Typography mb={2} variant="h6">For better performance, you can choose China Azure service.</Typography>
                            <TextField
                                margin="dense"
                                name="speechKey"
                                label="Speech Service Key"
                                type="text"
                                fullWidth
                                value={settings.speechKey}
                                onChange={handleChange}
                            />
                            <TextField
                                margin="dense"
                                name="speechRegion"
                                label="Speech Service Region"
                                type="text"
                                fullWidth
                                value={settings.speechRegion}
                                onChange={handleChange}
                            />
                            <TextField
                                margin="dense"
                                name="avatarKey"
                                label="Avatar Speech Service Key"
                                type="text"
                                fullWidth
                                value={settings.avatarKey}
                                onChange={handleChange}
                            />
                            <TextField
                                margin="dense"
                                name="avatarRegion"
                                label="Avatar Speech Service Region"
                                type="text"
                                fullWidth
                                value={settings.avatarRegion}
                                onChange={handleChange}
                            />
                        </TabPanel>
                        {errorMessage && (
                            <Box px={2} py={1}>
                                <Typography color="error">{errorMessage}</Typography>
                            </Box>
                        )}
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button type="submit" disabled={!isLoaded}>Save</Button>
                        </DialogActions>
                    </Box>
                </TabContext>
                <Typography variant="body1" ml={2} >Your left trail number is:{settings.trialNumber}</Typography>
            </Dialog>
        </>
    );
};

export default AppSettings;