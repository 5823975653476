import { LogLevel } from "@azure/msal-browser";
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0;

export const msalConfig = {
    auth: {
        clientId: "718270e6-cafe-427f-b025-c40df749d594",
        authority: "https://login.microsoftonline.com/common",
        redirectUri: process.env.REACT_APP_REDIRCT_URI,
        postLogoutRedirectUri: "/",
        
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: isIE || isEdge || isFirefox
    },
    allowedTenantDomains: ['demopro.net', 'microsoft.com'],
    system: {
        loadFrameTimeout: 15000, 
        loggerOptions: {
            loggerCallback: (level:any, message:any, containsPii:any) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        //console.info(message);
                        return;
                    case LogLevel.Verbose:
                        //console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};


// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["api://403b1d45-a3a5-4830-b168-a744ccec5454/access_as_user"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
//export const graphConfig = {
//    graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me"
//};